import permissions from '@/enums/permissions';

const onlineRegistrationRouter = [
    {
        path: '/cadastro-online',
        name: 'online-registration',
        component: () => import('@/views/pages/OnlineRegistration/index.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.onlineRegistration,
        },
    },
    {
        path: '/cadastro-online/cadastros',
        name: 'all-online-registration',
        component: () => import('@/views/pages/OnlineRegistration/Registrations.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.viewRegistersOnlineRegistration,
        },
    },
    {
        path: '/cadastro-online/:hash',
        name: 'online-registration-edit',
        component: () => import('@/views/pages/OnlineRegistration/index.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.updateOnlineRegistration,
        },
    },

    {
        path: '/cadastro-online/admin/contract',
        name: 'online-registration-edit',
        component: () => import('@/views/pages/OnlineRegistration/Admin/ImportContract.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.updateOnlineRegistration,
        },
    },

    {
        path: '/cadastro-online/admin/documentos',
        name: 'online-registration-edit',
        component: () => import('@/views/pages/OnlineRegistration/Admin/GiveDocumentOpinion.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.updateOnlineRegistration,
        },
    },
];

export default onlineRegistrationRouter;
