export default Object.freeze({
    ecommerce: 16,
    editEcommerce: 17,
    editUser: 18,
    newUser: 19,
    users: 20,
    viewProfile: 21,
    newProfile: 22,
    editProfile: 23,
    scr: 24,
    uploadVeripag: 25,
    uploadRede: 26,
    profileEcBusiness: 27,
    profileEcRisk: 28,
    mapPos: 29,
    consultTransaction: 30,
    profileGroupBusiness: 31,
    dashboardTransactions: 32,
    representatives: 33,
    charge: 34,
    transactionAnticipation: 36,
    riskDetails: 37,
    clientsWithoutTransaction: 42,
    customerStatement: 43,
    statementPosting: 44,
    registerFunds: 46,
    registerCdiVariation: 47,
    registerCdiMonthly: 47,
    registerIndicators: 47,
    registerProjection: 47,
    conciliation: 48,
    finishedConciliation: 48,
    liquidation: 50,
    tpv: 51,
    ecPerType: 52,
    uploadAccountEntry: 53,
    uploadStoreTree: 54,
    uploadFinancialPendencies: 55,
    dashboardDirectory: 59,
    uploadOpportunuties: 60,
    viewRegistersOnlineRegistration: 61,
    onlineRegistration: 62,
    updateOnlineRegistration: 62,
    reportAdjust: 63,
    comissionReport: 64,
    dashboardConciliation: 65,
    ecommerceDashboard: 66,
    chargeback: 67,
    dashboardChargeback: 67,
    chargebackDetails: 67,
    manageCreditPlatform: 69,
    uploadFileConciliation: 70,
    dashboardContribuition: 71,
    uploadRedemptionContribuition: 72,
    negativeClients: 73,
    dashboardNegativeClients: 73,
    dashboardRiskIndicators: 74,
    dashboardAtypicalTransactions: 75,
    dashboardReceiptsConciliation: 77,
    dashboardAccountPayable: 78,
    dashboardBudget: 79,
    dashboardReceivables: 80,
    dashboardGoals: 81,
    dashboardDRE: 82,
    dashboardRegisterOpportunites: 83,
    fileComercialProfitability: 84,
    transactionReport: 86,
    dashboardTransaction: 87,
    dashboardTransactionsInventory: 88,
    dashboardMeanTicket: 89,
    score: 90,
    accountEscrow: 91,
    dashboardMccValidation: 92,
    dashboardCedentSheet: 93,
    ntk: 94,
    dateDashboard: 95,
    activeService: 96,
    corporateActionPlan: 98,
    dashboardRoutine: 99,
    dashboardTransactionsSoulpay: 100,
    dashboardCCBCalculator: 101,
    terminalRental: 102,
    whatsappMessage: 103,
    whatsappGroup: 104,
    uploadBudget: 105,
    dashboardBudgetController: 106,
    tefTax: 107,
    rentTax: 108,
    clientsRentability: 109,
    assignors: 110,
    customerProfitability: 111,
    customerManagerDashboardBlacklist: 114,
    customerManagerDashboardClientSacker: 115,
    customerManagerDashboardToken: 116,
    fgtsDownload: 117,
    fgtsImport: 118,
    baseConciliation: 119,
    updateInstallmentsStatus: 120,
    canViewECs: 121,
});
